import React, {
    useEffect,
} from 'react';
import { useStore } from 'react-redux';

import { useOptimizelyTrack } from 'reaxl-optimizely';
import { AnalyticsProvider } from 'reaxl-analytics';
import { useFeatures } from 'reaxl-features';
import { useBrand } from 'reaxl-brand';
import _get from 'lodash/get';

import { DealerDetailsAnalyticsContainer } from '../../containers/dealerdetails';
import CommonHeadContainer from '../../containers/CommonHeadContainer';
import useAnalyticsProps from '../../analytics/useAnalyticsProps';
import DealerDetailsNewFeature from '../../containers/DealerDetailsNewFeature';
import { PersonalizationEngineContainer } from '../../containers';
import { atcBrand, kbbBrand, PAGE_NAME } from '../../constants/index';
import useDataLayer from '../../analytics/useDataLayer';
import { queryDuck, inventoryActiveOwnerDuck, ownersDuck } from '../../ducks';
import useShowComponent from '../../hooks/useShowComponent';

const DealerDetailsPage = (() => {
    const optimizelyTrack = useOptimizelyTrack();
    const analyticsProps = useAnalyticsProps();

    const store = useStore();
    const state = store.getState();

    const query = queryDuck.selectors.getDuckState(state);
    const activeOwner = inventoryActiveOwnerDuck.selectors.getDuckState(state);
    const owners = ownersDuck.selectors.getDuckState(state);
    const owner = _get(owners, activeOwner, {});

    const { disable_personalization_engine: [isPersonalizationEngineDisabled] } = useFeatures(['disable_personalization_engine']);
    const { isBrand } = useBrand();
    const isKbb = isBrand(kbbBrand);
    const dataLayer = useDataLayer(PAGE_NAME.DDP);
    const { showComponent } = useShowComponent(5000);

    const ddpAnalyticsProps = {
        ...analyticsProps,
        dataLayer: () => ({
            ...dataLayer,
        }),
    };

    /**
    * These codes using with optimizely
    * import { useFeatures } from 'reaxl-features';
    * const {
    *    ddp_v3: [, { enable_ddp_v3: enableNewFeature }],
    * } = useFeatures(['ddp_v3']);
    */

    useEffect(() => {
        optimizelyTrack('fyd_ddp_visit');
        optimizelyTrack('global_pageview');

        return () => {
            optimizelyTrack('fyd_ddp_exit');
        };
    }, []);

    if (!owner.id) return null;

    return (
        <>
            {showComponent && !isPersonalizationEngineDisabled && (
                <PersonalizationEngineContainer
                    pageName={PAGE_NAME.DDP}
                    brand={isKbb ? kbbBrand : atcBrand}
                />
            )}
            <AnalyticsProvider {...ddpAnalyticsProps}>
                <DealerDetailsAnalyticsContainer />
                <CommonHeadContainer
                    pageName={PAGE_NAME.DDP}
                    query={query}
                />
                <div id="new-ddp-section">
                    <DealerDetailsNewFeature />
                </div>
            </AnalyticsProvider>
        </>
    );
});

export default DealerDetailsPage;
